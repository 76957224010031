<template>
  <ListRole />
</template>


<script>
  import ListRole from '../../components/roles/Liste';

  export default {
    name: 'RoleListContainer',
    components: {
      ListRole,
    },
    data() {
      return {};
    },
  };
</script>
