<template>
  <div>
    <v-card>
      <div class="pa-5">
        <v-row>
          <v-col>
            <v-card-title
              primary-title
              class="title"
            >
              Rôles
            </v-card-title>
          </v-col>
          <v-col align="right">
            <v-bottom-sheet
              v-model="createRoleToggle"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>add</v-icon>
                  Ajouter un rôle
                </v-btn>
              </template>
              <v-sheet
                class="text-center overflow-y-auto"
              >
                <div class="py-2 px-5 col">
                  <v-row justify="center">
                    <v-col
                      :xl="3"
                      :lg="4"
                      :md="5"
                      :sm="8"
                      :xs="10"
                    >
                      <div class="subtitle-1 text-center d-flex justify-center mb-8">
                        <span v-if="!roleDuplicatedName">
                          Ajouter un rôle&nbsp;
                        </span>
                        <span
                          v-if="!roleDuplicatedName && currentCdpeIsNational()"
                          class="ml-1"
                        >
                          pour le national
                        </span>
                        <span
                          v-else-if="!roleDuplicatedName && currentCdpe"
                          class="ml-1"
                        >
                          pour le département {{ currentCdpe.nom }}
                        </span>
                        <span v-if="roleDuplicatedName">
                          Dupliquer le rôle {{ roleDuplicatedName }}
                        </span>
                      </div>
                      <v-form
                        ref="form"
                        @submit.prevent="validate"
                      >
                        <v-text-field
                          v-model="roleToCreate.suffixe"
                          v-validate="'required'"
                          label="Nom"
                          required
                          data-vv-name="nom"
                          :error-messages="errors.collect('nom')"
                        />
                        <v-select
                          v-if="!currentCdpeIsNational()"
                          v-model="roleToCreate.typePerimetreId"
                          v-validate="'required'"
                          :items="itemsTypePerimetre"
                          label="Type de rôle"
                          :item-text="item => item.nom"
                          :item-value="item => item.code"
                          data-vv-name="type de rôle"
                          :error-messages="errors.collect('type de rôle')"
                          :disabled="roleDuplicatedName !== ''"
                        />
                        <v-btn
                          :disabled="!formValid"
                          color="success"
                          class="mr-4"
                          type="submit"
                        >
                          Enregister
                        </v-btn>
                        <v-btn
                          class="mr-4"
                          color="error"
                          @click="createRoleToggle = !createRoleToggle; roleDuplicatedName = '';"
                        >
                          Fermer
                        </v-btn>
                      </v-form>
                    </v-col>
                  </v-row>
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </v-col>
        </v-row>
        <v-row class="xs14">
          <v-col
            v-if="isUserNational"
            cols="12"
            md="6"
            class="text-right"
          >
            <v-autocomplete
              v-model="currentCdpe"
              clearable
              :items="getFilterDepartementsWithNAT"
              label="Département"
              :item-text="item => `${item.code} - ${item.nom}`"
              return-object
              @change="changeCdpe"
            />
          </v-col>
        </v-row>
        <v-tabs
          v-model="active_tab"
          class="subheading"
          centered
          icons-and-text
        >
          <v-tabs-slider />
          <v-tab
            v-if="currentCdpeIsNational()"
            href="#tab-nat"
          >
            Rôles nationaux
          </v-tab>
          <v-tab
            v-if="!currentCdpeIsNational()"
            href="#tab-dep"
            @change="onDepartementalTab" 
          >
            Rôles départementaux
          </v-tab>
          <v-tab
            v-if="!currentCdpeIsNational()"
            href="#tab-loc"
            @change="onLocalTab" 
          >
            Rôles locaux
          </v-tab>
          <v-tab-item
            value="tab-nat"
          >
            <v-data-table
              light
              :headers="headers"
              class="elevation-3"
              :loading="loading"
              loading-text="Chargement en cours"
              no-data-text="Aucun rôle trouvé"
              :items="nationalList"
              :sort-by="['nom']"
              :items-per-page="50"
              :footer-props="{
                itemsPerPageText: 'Rôles par page:',
                itemsPerPageOptions: [20, 50, 100, 200]
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop
                    >
                      more_vert
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        :to="{ name: 'edition-role', params: { roleName: item.nom }}"
                      >
                        <v-list-item-action
                          :key="item.nom"
                        >
                          Modifier
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action
                          :key="item.nom"
                          @click.stop="duplicate(item.nom)"
                        >
                          Dupliquer
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="isRoleDeletable(item.nom)">
                        <v-list-item-action
                          :key="item.nom"
                          @click.stop="openModal(item.nom)"
                        >
                          Supprimer
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item
            value="tab-dep"
          >
            <v-data-table
              light
              :headers="headers"
              class="elevation-3"
              :loading="loading"
              loading-text="Chargement en cours"
              no-data-text="Aucun rôle trouvé"
              :items="departementalList"
              :sort-by="['nom']"
              :items-per-page="50"
              :footer-props="{
                itemsPerPageText: 'Rôles par page:',
                itemsPerPageOptions: [20, 50, 100, 200]
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop
                    >
                      more_vert
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        :to="{ name: 'edition-role', params: { roleName: item.nom }}"
                      >
                        <v-list-item-action
                          :key="item.nom"
                        >
                          Modifier
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action
                          :key="item.nom"
                          @click.stop="duplicate(item.nom)"
                        >
                          Dupliquer
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="isRoleDeletable(item.nom)">
                        <v-list-item-action
                          :key="item.nom"
                          @click.stop="openModal(item.nom)"
                        >
                          Supprimer
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item
            value="tab-loc"
          >
            <v-data-table
              light
              :headers="headers"
              class="elevation-3"
              :loading="loading"
              loading-text="Chargement en cours"
              no-data-text="Aucun rôle trouvé"
              :items="localList"
              :sort-by="['nom']"
              :items-per-page="50"
              :footer-props="{
                itemsPerPageText: 'Rôles par page:',
                itemsPerPageOptions: [20, 50, 100, 200]
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop
                    >
                      more_vert
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        :to="{ name: 'edition-role', params: { roleName: item.nom }}"
                      >
                        <v-list-item-action
                          :key="item.nom"
                        >
                          Modifier
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action
                          :key="item.nom"
                          @click.stop="duplicate(item.nom)"
                        >
                          Dupliquer
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="isRoleDeletable(item.nom)">
                        <v-list-item-action
                          :key="item.nom"
                          @click.stop="openModal(item.nom)"
                        >
                          Supprimer
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </div>
      <ConfirmedModal
        v-if="showConfirmDelete"
        :sentence="modalSentence"
        :btn-action="modalBtnAction"
        @close="resetDelete"
        @confirmed="doDeleteRole"
      />
    </v-card>
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import typePerimetre, { PERIMETRE_NATIONAL_ID } from './../../auth/typePerimetre.js';
  import ConfirmedModal from './../reusableComponents/confirmedModal';

  export default {
    component:{
      typePerimetre,
    },
    name: 'ListRoles',
    components:{
      ConfirmedModal,
    },
    data: () => ({
      modalSentence: 'Êtes-vous sûr de vouloir supprimer ce role ?',
      modalBtnAction: 'Supprimer',
      showConfirmDelete: false,
      active_tab: 'tab-nat',
      departementalList: [],
      localList: [],
      nationalList: [],
      currentCdpe: null,
      itemsTypePerimetre: [{ nom:'Départemental',code: typePerimetre.DEPARTEMENTAL }, { nom:'Local',code: typePerimetre.LOCAL }],
      roleToDelete: null,
      roleToCreate: {
        perimetreId: null,
        suffixe: '',
        typePerimetreId: null,
      },
      createRoleToggle: false,
      roleDuplicatedName: '',
      headers: [
        {
          text: 'Action',
          sortable: true,
          value: 'action',
        },
        {
          text: 'Nom',
          sortable: true,
          value: 'nom',
        },
      ],
    }),

    computed: {
      ...mapState('parametrages/role', {
        rolesByPerimetreId: state => state.rolesByPerimetreId,
        loading: state => state.loading,
        stateCurrentDepartement: state => state.currentDepartement,
      }),
      ...mapGetters('session', [
        'isUserNational',
        'isUserDepartemental',
        'isUserLocal',
        'getPerimetreUser',
      ]),
      ...mapGetters('cdpe', [
        'getFilterDepartements',
      ]),
      getFilterDepartementsWithNAT() {
        return [
          {
            id: PERIMETRE_NATIONAL_ID,
            code: 'NAT',
            nom: 'National',
          },
        ].concat(this.getFilterDepartements);
      },
      formValid() {
        // loop over all contents of the fields object and check if they exist and valid.
        let valid =  Object.keys(this.fields).every(field => {
          return this.fields[field] && this.fields[field].valid;
        });
        //Save validation state in store, container will use it to desactivate save button
        // this.$store.commit('_parent/setFormValid', valid);
        return valid;
      },
    },
    watch: {
      rolesByPerimetreId(val){
        this.nationalList = val.national;
        if (val.national.length == 0) {
          this.departementalList = val.departemental;
          this.localList = val.local;
        } else {
          this.departementalList = [];
          this.localList = [];
        }
      },
    },
    created() {
      if (this.isUserNational) {
        this.loadFilterDepartements();
      }
      if(this.stateCurrentDepartement === null){
        this.currentCdpe = {
          id: this.getPerimetreUser.perimetre_id,
          code: this.getPerimetreUser.code,
          nom: this.getPerimetreUser.nom,
        };
        this.setCurrentDepartement(this.currentCdpe);
      }else{
        this.currentCdpe = this.stateCurrentDepartement;
      }
      this.loadRolesByPerimetreId(this.currentCdpe.id);
    },
    methods: {
      ...mapActions('cdpe', [
        'loadFilterDepartements',
      ]),
      ...mapActions('parametrages/role', [
        'loadRolesByPerimetreId',
        'createRole',
        'deleteRole',
        'setCurrentDepartement',
      ]),
      isRoleDeletable(nom){
        let regex = /ROLE_(?:DEPARTEMENTAL_\d{3}|LOCAL_\d{3}|NATIONAL)$/g;
        return !nom.match(regex);
      },
      openModal (id) {
        this.showConfirmDelete = true;
        this.roleToDelete = id;
      },
      doDeleteRole () {
        this.deleteRole({
          'roleToDelete': this.roleToDelete,
          'perimetreId': this.currentCdpe.id,
        }).then(
          this.resetDelete()
      );
      },
      resetDelete(){
        this.showConfirmDelete = false;
        this.roleToDelete = null;
      },
      changeCdpe (val) {
        if (!val) {
          return;
        }
        this.loadFilterDepartements(this.currentCdpe);
        this.loadRolesByPerimetreId(this.currentCdpe.id);
        this.setCurrentDepartement(this.currentCdpe);
        this.active_tab = 'tab-dep';
        this.roleToCreate.typePerimetreId = typePerimetre.DEPARTEMENTAL;
      },
      validate () {
        this.$validator.validateAll();
        if (!this.formValid) {
          return;
        }
        if (this.currentCdpeIsNational()) {
          this.roleToCreate.typePerimetreId = typePerimetre.NATIONAL;
        }
        this.roleToCreate.perimetreId = this.currentCdpe.id;
        this.createRole({
          'roleToCreate': this.roleToCreate,
          'perimetreId': this.currentCdpe.id,
        }).then(
          this.createRoleToggle = false,
          this.roleDuplicatedName = '',
        );
      },
      duplicate(nom) {
        let regex = /ROLE_((?:DEPARTEMENTAL_\d{3}|LOCAL_\d{3}|NATIONAL))_?(.*)?/;
        let role = nom.match(regex)[1];
        if (role.includes('NATIONAL')) {
          this.roleToCreate.typePerimetreId = typePerimetre.NATIONAL;
        } else if (role.includes('DEPARTEMENTAL')) {
          this.roleToCreate.typePerimetreId = typePerimetre.DEPARTEMENTAL;
        } else {
          this.roleToCreate.typePerimetreId = typePerimetre.LOCAL;
        }
        this.roleToCreate.suffixe = nom.match(regex)[2] ? nom.match(regex)[2] : '';
        this.roleToCreate.perimetreId = this.currentCdpe.id;
        this.roleDuplicatedName = nom;
        this.createRoleToggle = true;
      },
      currentCdpeIsNational() {
        return !this.currentCdpe || this.currentCdpe.id === PERIMETRE_NATIONAL_ID;
      },
      onDepartementalTab() {
        this.roleToCreate.typePerimetreId = typePerimetre.DEPARTEMENTAL;
      },
      onLocalTab() {
        this.roleToCreate.typePerimetreId = typePerimetre.LOCAL;
      },
    },
  };
</script>
